import { Box, Flex, Heading, Image, PrimaryButton, SimpleGrid, Stack, TertiaryButton, Text } from '@booket-uk/component-library';
import Barber from '../../../undraw/undraw_people_tax5.svg';
import { ManageTeamMembers } from '../../LoggedIn/Settings/Users/v2';
import React from 'react';
import { useProviderId } from '../../hooks/useProviderId';
import { BooketApi } from '../../../Booket';

export const YourTeamMembers = ({ onSuccess, onBack }: { onSuccess: () => void; onBack: () => void }) => {
  const { providerId } = useProviderId();
  const onSubmit = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    BooketApi.providers.update(providerId, {
      onboardingState: 'SETUP_SERVICES'
    });
    onSuccess();
  };
  return (
    <>
      <SimpleGrid columns={[1, 1, 1, 3]} style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <Box p={[4, 8, 16]}>
            <Stack spacing={5}>
              <Heading pb={6}>Anymore team members?</Heading>
              <Box>
                <ManageTeamMembers modes={['QUICK_EDIT', 'DELETABLE']} colorScheme="teal" />
              </Box>
              <Flex mt={8}>
                <TertiaryButton size="lg" mr={4} onClick={onBack}>
                  Back
                </TertiaryButton>
                <PrimaryButton size="lg" width="full" type="submit" onClick={onSubmit}>
                  Next
                </PrimaryButton>
              </Flex>
            </Stack>
          </Box>
        </div>
        <Flex className="hide-tablet" bg="cool-gray.200" alignItems="center" flexDirection="column" py={20} justifyContent="center">
          <Image src={Barber} boxSize="45%" />
          <Heading maxWidth={440} textAlign="center">
            {/*Fun fact!*/}
          </Heading>
          <Text fontSize="lg" mt={6} maxWidth={280} textAlign="center">
            When we get you in you can customise these further with different service pricing and opening hours
          </Text>
          <Text fontSize="sm" fontWeight="bold"></Text>
        </Flex>
      </SimpleGrid>
    </>
  );
};
