import * as React from 'react';
import { ErrorInfo } from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import {
  Background,
  Panel,
  Flex,
  PanelHeader,
  PanelFooter,
  PanelBody,
  Text,
  theme,
  PrimaryButton,
  TertiaryButton,
  ChakraProvider
} from '@booket-uk/component-library';
import * as Sentry from '@sentry/browser';
// Concurrent mode
// const dom = ReactDOM as any;
// dom.unstable_createRoot(document.getElementById('root')).render(<App />);

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {});
} else {
  Sentry.init({
    dsn: 'https://0e82b6698ee74495b9bdb697e04dc050@o404087.ingest.sentry.io/5267376',
    release: process.env.REACT_APP_CURRENT_GIT_SHA
  });
}

class GlobalErrorBoundary extends React.Component {
  state = {
    hasError: false,
    eventId: ''
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    window.localStorage.clear();
    Sentry.withScope(scope => {
      scope.setTag('componentStack', errorInfo.componentStack);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      navigator.userAgent.indexOf('Version/9.') !== -1 &&
        navigator.userAgent.indexOf('Safari') !== 1 &&
        window.alert(
          "We have detected that you are using iOS version 9.\n\nUnfortunately this version has issues with date & timezones which is crucial to accurate booking functionality\n\nYou should upgrade to a new version of iOS on your device by going to 'Settings' > 'General' > 'Software update'"
        );
      return (
        <ChakraProvider theme={theme}>
          <Flex justifyContent="center" alignItems="center">
            <Background />
            <Panel width={['100%', '100%', 'xl']} mt={6}>
              <PanelHeader px={6} pt={6} pb={4}>
                Whoops!
              </PanelHeader>
              <PanelBody px={6}>
                <Text>We hit an unexpected error! We're sorry this happened and our team have been notified.</Text>
                <Text mt={4}>
                  If you’d like to help, click "Tell us what happened" and fill out what you were doing prior to this happening.
                </Text>
                <Text mt={4}>If you are stuck in a loop with this error please contact us.</Text>
              </PanelBody>
              <PanelFooter>
                {this.state.eventId && (
                  <TertiaryButton mr={2} onClick={() => Sentry.showReportDialog({ eventId: (this.state as any).eventId })}>
                    Tell us what happened?
                  </TertiaryButton>
                )}
                <PrimaryButton as="a" {...{ href: 'https://provider.booket.co', style: { flexGrow: 1 } }}>
                  Try again
                </PrimaryButton>
              </PanelFooter>
            </Panel>
          </Flex>
        </ChakraProvider>
      );
    } else {
      return this.props.children;
    }
  }
}

//  Normal mode
ReactDOM.render(
  <GlobalErrorBoundary>
    <App />
  </GlobalErrorBoundary>,
  document.getElementById('root')
);

// if (window.devicePixelRatio !== 1){
//   let scaleValue = (1/window.devicePixelRatio);
//   document.body.style = scaleValue
// }

// document.body.style.transform = 'scale(1)'
