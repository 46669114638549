import React, { PropsWithChildren } from 'react';
import { Hours } from '@booket-uk/api/dist/providers/types';
import {
  Flex,
  Heading,
  IconButton,
  Image,
  PrimaryButton,
  Box,
  SimpleGrid,
  Skeleton,
  Stack,
  TertiaryButton,
  Text
} from '@booket-uk/component-library';
import Clock from '../../../undraw/undraw_time_management_30iu.svg';
import { TimeSelect } from '../TimeSelect';
import _deepClone from 'lodash/cloneDeep';
import { BooketApi } from '../../../Booket';
import { useProviderId } from '../../hooks/useProviderId';
import { usePublishedHours } from '../../hooks/usePublishedHours';
import { DateTime } from 'luxon';
import { HiPlus, HiTrash } from 'react-icons/hi';

const NINE_TO_FIVE = () => ({ start: '0900', ends: '1700' });

export const days = {
  monday: 'M',
  tuesday: 'T',
  wednesday: 'W',
  thursday: 'T',
  friday: 'F',
  saturday: 'S',
  sunday: 'S'
};

export const YourPublishedOpeningHours = ({ onSuccess }: { onSuccess: () => void }) => {
  const { providerId } = useProviderId();
  const onSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    BooketApi.providers.update(providerId, {
      onboardingState: 'SETUP_TEAM_MEMBERS'
    });
    onSuccess();
  };

  return (
    <SimpleGrid columns={[1, 1, 1, 3]} style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
      <form onSubmit={onSubmit} style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
        <Box p={[4, 8, 16]}>
          <Stack spacing={5}>
            <Heading pb={6}>Published opening hours?</Heading>
            <ManageOpeningHours />
            <PrimaryButton size="lg" type="submit" isLoading={false} mt={6}>
              Next
            </PrimaryButton>
          </Stack>
        </Box>
      </form>
      <Flex className="hide-tablet" bg="cool-gray.200" alignItems="center" flexDirection="column" py={20} justifyContent="center">
        <Image src={Clock} boxSize="45%" />
        <Heading maxWidth={440} textAlign="center">
          Fun fact!
        </Heading>
        <Text fontSize="lg" mt={6} maxWidth={280} textAlign="center">
          84% of salons are closed on Sunday and Monday
          {/*We will publish these online to help attract customer walk-ins*/}
        </Text>
        <Text fontSize="sm" fontWeight="bold"></Text>
      </Flex>
    </SimpleGrid>
  );
};

export const OpeningHourLine = ({ label, value, onChange, onAll, ...other }: any) => {
  const isChecked = value && value.length > 0;
  const _onCheck = () => {
    if (isChecked) {
      onChange([]);
    } else {
      onChange([NINE_TO_FIVE()]);
    }
  };

  const _onStartChange = (index: number, nextVal: string) => {
    const next = [...value];
    next[index].start = nextVal;
    if (next[index].start > next[index].ends) {
      next[index].ends = nextVal;
    }
    onChange(next);
  };

  const _onEndChange = (index: number, nextVal: string) => {
    const next = [...value];
    next[index].ends = nextVal;
    onChange(next);
  };

  const _onAdd = (index: number) => {
    const next = [...value];
    next.push({ start: next[index].ends, ends: next[index].ends });
    onChange(next);
  };

  const _onRemove = (index: number) => {
    const next = [...value];
    next.splice(index, 1);
    onChange(next);
  };

  return (
    <Flex {...other}>
      <Box mr={8}>
        <DayCheckbox name="mon" isChecked={isChecked} onChange={_onCheck}>
          {label}
        </DayCheckbox>
      </Box>
      <Stack spacing={1}>
        {isChecked &&
          value.map((time: Hours, index: number) => (
            <Flex alignItems="center" key={index}>
              <Box>
                <TimeSelect size="lg" value={time.start} onChange={(nextVal: string) => _onStartChange(index, nextVal)} />
              </Box>
              <Text px={4}>to</Text>
              <Box>
                <TimeSelect
                  size="lg"
                  value={time.ends}
                  notBefore={time.start}
                  onChange={(nextVal: string) => _onEndChange(index, nextVal)}
                />
              </Box>
              <Box>
                <IconButton icon={<HiPlus />} aria-label="Split" ml={4} size="lg" onClick={() => _onAdd(index)} />{' '}
                {index > 0 && (
                  <IconButton icon={<HiTrash />} aria-label="Split" ml={2} colorScheme="red" size="lg" onClick={() => _onRemove(index)} />
                )}
              </Box>

              {onAll && index === 0 && (
                <Box className="hide-mobile">
                  <TertiaryButton onClick={() => onAll(value)} ml={4}>
                    Copy to all
                  </TertiaryButton>
                </Box>
              )}
            </Flex>
          ))}
      </Stack>
    </Flex>
  );
};

const DayCheckbox = ({
  name,
  children,
  onChange,
  isChecked
}: PropsWithChildren<{ name: string; onChange: (val: boolean) => void; isChecked: boolean }>) => {
  return (
    <Box
      as="label"
      display="flex"
      height="3.5rem"
      width="3.5rem"
      fontSize="lg"
      cursor="pointer"
      position="relative"
      bg="cool-gray.200"
      rounded="full"
      justifyContent="center"
      alignItems="center"
      userSelect="none"
      fontWeight="semibold"
      _hover={{
        bg: 'cool-gray.300'
      }}
      _focus={{
        bg: 'cool-gray.300',
        outline: 'none'
      }}
      aria-checked={isChecked ? 'true' : 'false'}
      _checked={{
        fontWeight: 'semibold',
        color: 'white',
        bg: 'teal.600'
      }}
    >
      <Box
        as="input"
        {...{ type: 'checkbox', checked: isChecked, onChange: () => onChange(!isChecked), name }}
        border="0px none"
        height="1px"
        width="1px"
        margin="-1px"
        padding={0}
        overflow="hidden"
        position="absolute"
        style={{ clip: 'rect(0px, 0px, 0px, 0px)' }}
      />
      {children}
    </Box>
  );
};

export const ManageOpeningHours = () => {
  const { allHours, isLoading }: any = usePublishedHours({ selectedDate: DateTime.local() });
  const { providerId } = useProviderId();

  const setHours = (stateHours: any) => {
    BooketApi.providers.update(providerId, {
      publishedHours: [{ effectiveFrom: new Date(), effectiveTo: new Date(3000, 1), hours: stateHours }]
    });
  };

  const onAll = (change: Hours[]) => {
    const hour = Object.keys(days).reduce((agg, day) => {
      if (agg[day].length > 0) {
        agg[day] = _deepClone(change);
      }
      return agg;
    }, _deepClone(allHours));
    setHours(hour);
  };

  if (isLoading) {
    return (
      <Box>
        <Skeleton height={78} mb={6} />
        <Skeleton height={78} mb={6} />
        <Skeleton height={78} mb={6} />
        <Skeleton height={78} mb={6} />
        <Skeleton height={78} mb={6} />
        <Skeleton height={78} mb={6} />
        <Skeleton height={78} mb={6} />
      </Box>
    );
  }

  const daysArr = Object.keys(days);
  let dayWithFirstHoursOn = 'monday';
  for (let i = 0; i < daysArr.length; i++) {
    if (allHours[dayWithFirstHoursOn].length > 0) {
      break;
    } else {
      dayWithFirstHoursOn = daysArr[i + 1] || 'monday';
    }
  }

  return (
    <Stack spacing={6}>
      {daysArr.map(key => (
        <OpeningHourLine
          key={key}
          label={days[key]}
          value={allHours[key]}
          onChange={(change: Hours[]) => {
            setHours({ ...allHours, [key]: change });
          }}
          onAll={dayWithFirstHoursOn === key ? onAll : null}
        />
      ))}
    </Stack>
  );
};
