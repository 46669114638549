import React, { useEffect } from 'react';
import { Auth } from '../../Firebase';
import ProviderStore from '../../Services/ProviderStore';
import { log } from 'log';

const LogOut = ({ onLogout }: { onLogout: () => void }) => {
  useEffect(() => {
    logout();

    async function logout() {
      log.info('[Logout] Started');

      if (ProviderStore.INSTANCE) {
        ProviderStore.INSTANCE.unsubscribe();
      }
      Auth.signOut()
        .then(() => {
          log.info('[Logout] successfully signed out');
          window.localStorage.clear();
          onLogout();
        })
        .catch((error: any) => {
          alert('Error logging out. We suggest you clear your browser cache. Error:' + error);
        });
    }
  });
  return <></>;
};

export default LogOut;
