import { CustomerSummary, Product, ProductGroup, Provider, Service, TeamMember } from '@booket-uk/api/dist/providers/types';
import { Group } from '@booket-uk/api/dist/services/types';
import Booket from '../Booket';
import { log } from '../log';
import firebase from 'firebase/app';

export interface IProviderStoreFields {
  provider: Provider;
  teamMembers: TeamMember[];
  groups: Group[];
  services: Service[];
  products: Product[];
  productGroups: ProductGroup[];
  customers: CustomerSummary[];
  cardReader: any;
  paymentGateways: any;
  managementPin: {
    isEnabled: boolean;
    pin?: string;
  };
}

class ProviderStore {
  static INSTANCE?: ProviderStore;
  providerId: string;
  provider?: Provider;
  teamMembers?: TeamMember[];
  groups?: Group[];
  services?: Service[];
  products?: Product[];
  productGroups?: ProductGroup[];
  customers: CustomerSummary[] = [];
  customersUnsorted: CustomerSummary[] = [];
  cardReader?: any;
  paymentGateways?: any;
  managementPin?: {
    isEnabled: boolean;
    pin?: string;
  };

  unsubs: Array<() => void> = [];
  listeners: Array<(store: IProviderStoreFields) => void> = [];

  requirements: string[] = [
    'provider',
    'products',
    'cardReader',
    'product-groups',
    'teamMembers',
    'services',
    'groups',
    'customers',
    'payment-gateways',
    'management-pin'
  ];
  completed = {};
  i = 0;

  private constructor(providerId: string) {
    this.providerId = providerId;
    this.subscribe();
  }

  static createInstance(providerId: string) {
    ProviderStore.INSTANCE = new ProviderStore(providerId);
  }

  static getInstance(providerId: string) {
    if (!ProviderStore.INSTANCE) {
      this.createInstance(providerId);
    }
    if (ProviderStore.INSTANCE && ProviderStore.INSTANCE.providerId !== providerId) {
      this.createInstance(providerId);
    }
    return ProviderStore.INSTANCE as ProviderStore;
  }

  private subscribe() {
    const providerId = this.providerId;
    this.unsubs.push(Booket.providers.onSnapshot({ providerId }, this._onProviderSnapshot.bind(this)));
    this.unsubs.push(Booket.providers.teamMembers.onSnapshot({ providerId }, this._onTeamMemberSnapshot.bind(this)));
    this.unsubs.push(Booket.services.onGroupSnapshot({ providerId }, this._onGroupsSnapshot.bind(this)));
    this.unsubs.push(Booket.services.onSnapshot({ providerId }, this._onServicesSnapshot.bind(this)));
    this.unsubs.push(Booket.providers.customers.onSnapshot({ providerId }, this._onCustomersSnapshot.bind(this)));
    this.unsubs.push(Booket.products.onSnapshot({ providerId }, this._onProductsSnapshot.bind(this)));
    this.unsubs.push(Booket.products.onGroupSnapshot({ providerId }, this._onProductGroupsSnapshot.bind(this)));
    this.unsubs.push(
      Booket.integrations.subscribeTo(
        {
          providerId,
          name: 'payworks-card-reader'
        },
        this._onCardReaderSnapshot.bind(this)
      )
    );
    this.unsubs.push(
      Booket.integrations.subscribeTo(
        {
          providerId,
          name: 'payment-gateways'
        },
        this._onPaymentGatewaysSnapshot.bind(this)
      )
    );
    this.unsubs.push(
      Booket.integrations.subscribeTo(
        {
          providerId,
          name: 'management-pin'
        },
        this._onManagementPin.bind(this)
      )
    );
  }

  unsubscribe() {
    this.unsubs.forEach((unsub: () => void) => {
      unsub();
    });
    delete ProviderStore.INSTANCE;
  }

  private _onProviderSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
    this.provider = { id: snapshot.id, ...snapshot.data() } as Provider;
    log.debug('_onProviderSnapshot', snapshot.data());
    this._selfNotify('provider');
  }

  private _onCardReaderSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
    if (snapshot.exists) {
      log.debug('_onCardReaderSnapshot', snapshot.data());
      this.cardReader = snapshot.data();
    }
    this._selfNotify('cardReader');
  }

  private _onPaymentGatewaysSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
    if (snapshot.exists) {
      this.paymentGateways = (snapshot.data() as any).gateways;
    }
    log.debug('_onPaymentGatewaysSnapshot', snapshot.data());
    this._selfNotify('payment-gateways');
  }

  private _onManagementPin(snapshot: firebase.firestore.DocumentSnapshot) {
    if (snapshot.exists) {
      log.debug('_onManagementPin', snapshot.data());
      this.managementPin = snapshot.data() as any;
    } else {
      this.managementPin = {
        isEnabled: false
      };
    }
    this._selfNotify('management-pin');
  }

  private async _onTeamMemberSnapshot(snapshot: firebase.firestore.QuerySnapshot) {
    this.i += snapshot.size;
    this.teamMembers = await Promise.all(
      snapshot.docs
        .map((doc: firebase.firestore.QueryDocumentSnapshot) => ({ id: doc.id, ...doc.data() }))
        .filter((teamMember: any) => teamMember.status >= 0)
        .map(async (tm: any) => {
          if (tm.workingHours) {
            tm.workingHours = tm.workingHours.map((wh: any) => ({
              effectiveFrom: wh.effectiveFrom.toDate(),
              effectiveTo: wh.effectiveTo.toDate(),
              hours: wh.hours
            }));
          }
          tm.services = (
            await Booket.providers.teamMembers.getServices({
              providerId: this.providerId,
              teamMemberId: tm.id
            })
          ).docs.map((docw: firebase.firestore.QueryDocumentSnapshot) => {
            return docw.data();
          });
          this.i += tm.services.length;
          return Object.assign({ id: tm.id }, tm as any) as TeamMember;
        })
    );
    log.debug('_onTeamMemberSnapshot', snapshot.docs);
    this._selfNotify('teamMembers');
  }

  private _onGroupsSnapshot(snapshot: firebase.firestore.QuerySnapshot) {
    this.i += snapshot.size;
    this.groups = snapshot.docs.map((doc: firebase.firestore.QueryDocumentSnapshot) => {
      return Object.assign({ id: doc.id }, doc.data() as Group) as Group;
    });
    log.debug('_onGroupsSnapshot', snapshot.docs);
    this._selfNotify('groups');
  }

  private _onProductGroupsSnapshot(snapshot: firebase.firestore.QuerySnapshot) {
    this.i += snapshot.size;
    this.productGroups = snapshot.docs.map((doc: firebase.firestore.QueryDocumentSnapshot) => {
      return Object.assign({ id: doc.id }, doc.data()) as ProductGroup;
    });
    this._selfNotify('product-groups');
  }

  private _onServicesSnapshot(snapshot: firebase.firestore.QuerySnapshot) {
    this.i += snapshot.size;
    this.services = snapshot.docs.map((doc: firebase.firestore.QueryDocumentSnapshot) => {
      return { ...doc.data(), id: doc.id } as Service;
    });
    log.debug('_onServicesSnapshot', snapshot.docs);

    this._selfNotify('services');
  }

  private _onProductsSnapshot(snapshot: firebase.firestore.QuerySnapshot) {
    this.i += snapshot.size;
    this.products = snapshot.docs.map((doc: firebase.firestore.QueryDocumentSnapshot) => {
      return Object.assign({ id: doc.id }, doc.data() as Product) as Product;
    });
    log.debug('_onProductsSnapshot', snapshot.docs);
    this._selfNotify('products');
  }

  private _onCustomersSnapshot(snapshot: firebase.firestore.QuerySnapshot) {
    this.i += snapshot.size;
    snapshot.docChanges().forEach((change: any) => {
      const customer = Object.assign({ id: change.doc.id }, change.doc.data() as CustomerSummary) as CustomerSummary;
      if (change.type === 'added') {
        console.log('added', change.oldIndex, change.newIndex);
        this.customersUnsorted.splice(change.newIndex, 0, customer);
      }
      if (change.type === 'modified') {
        console.log('modified', change.oldIndex, change.newIndex);
        if (change.oldIndex !== change.newIndex) {
          this.customersUnsorted.splice(change.oldIndex, 1);
          this.customersUnsorted.splice(change.newIndex, 0, customer);
        } else {
          this.customersUnsorted[change.newIndex] = customer;
        }
      }
      if (change.type === 'removed') {
        console.log('removed', change.oldIndex, change.newIndex);
        this.customersUnsorted.splice(change.oldIndex, 1);
      }
    });
    log.debug('_onCustomersSnapshot', snapshot.docs);
    const unsorted = [...this.customersUnsorted];
    unsorted.sort((a, b) => {
      if (a && a.displayName && b && b.displayName) {
        return a.displayName.localeCompare(b.displayName);
      } else {
        return -1;
      }
    });
    this.customers = unsorted;
    this._selfNotify('customers');
  }

  listen(callback: (store: IProviderStoreFields) => void) {
    const loc = this.listeners.push(callback);
    if (this._isReady()) {
      callback(this as IProviderStoreFields);
    }

    return () => {
      this.listeners.splice(loc - 1, 1);
    };
  }

  _selfNotify(what: string) {
    this.completed[what] = true;
    if (this._isReady()) {
      this.notify();
    }
  }

  private _isReady() {
    return this.requirements.every(entry => this.completed[entry]);
  }

  notify() {
    this.listeners.forEach((listener: (store: IProviderStoreFields) => void) => {
      listener(this as IProviderStoreFields);
    });
  }
}

export default ProviderStore;
