import {
  Alert,
  Flex,
  Loader,
  Text,
  AlertDescription,
  AlertIcon,
  Background,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  TertiaryButton,
  theme,
  ChakraProvider,
  extendTheme
} from '@booket-uk/component-library';
import { Settings } from 'luxon';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, useHistory } from 'react-router-dom';
import './App.css';
import asyncComponent from './Components/AsyncComponent';
import './Firebase';
import './luxon-firestore';
import { assign, createMachine } from 'xstate';
import { useMachine } from '@xstate/react/lib';
import { Auth } from './Firebase';
import firebase from 'firebase/app';
import Content from './Components/Content';
import { AuthContextProvider } from './Containers/LoggedIn/ProviderContext';
import Booket from './Booket';
import LogOut from './Containers/LogOut';
import { Login } from './Containers/Login';
import { Registration } from './Containers/Registration';

const LoggedIn = asyncComponent(() => import('./Containers/LoggedIn'));
const PasswordReset = asyncComponent(() => import('./Containers/PasswordReset'));

Settings.defaultZoneName = 'Europe/London';

const checkCurrentUserState = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = Auth.onAuthStateChanged(
      (auth: firebase.User | null) => {
        unsubscribe();
        resolve(auth);
      },
      (error: firebase.auth.Error) => {
        reject(error);
      }
    );
  });
};

const logProviderIn = async (ctx: any) => {
  try {
    const providerId = await Booket.users.getOwnerOf({ uid: ctx.user.uid });
    const provider = await Booket.providers.get({ providerId });
    return { provider, providerId };
  } catch (err) {
    throw err;
  }
};

const AppStartMachine: any = {
  id: 'app-state-makeBookingRequest',
  initial: 'check-auth-state',
  context: {
    retries: 0,
    maxRetries: 3
  },
  states: {
    'check-auth-state': {
      invoke: {
        src: checkCurrentUserState,
        onDone: [
          {
            target: 'user-logged-in',
            cond: (ctx: any, event: any) => event.data,
            actions: assign({
              user: (ctx: any, event: any) => event.data
            })
          },
          {
            target: 'user-logged-out'
          }
        ],
        onError: {
          target: 'error',
          actions: assign({
            error: (ctx: any, event: any) => event.data
          })
        }
      }
    },
    'user-logged-out': {
      on: {
        ONBOARDED: {
          target: 'check-auth-state'
        },
        LOG_IN: {
          target: 'check-auth-state'
        }
      }
    },
    'user-logged-in': {
      invoke: {
        src: logProviderIn,
        onDone: [
          {
            target: 'provider-logged-in',
            cond: (ctx: any, event: any) => event.data && event.data.provider.onboardingState === 'ONBOARDED',
            actions: assign({
              error: undefined,
              retries: 0,
              providerId: (ctx: any, event: any) => event.data.providerId,
              provider: (ctx: any, event: any) => event.data.provider
            })
          },
          {
            target: 'provider-onboarding',
            cond: (ctx: any, event: any) => event.data,
            actions: assign({
              error: undefined,
              retries: 0,
              providerId: (ctx: any, event: any) => event.data.providerId,
              provider: (ctx: any, event: any) => event.data.provider
            })
          },
          {
            target: 'user-logged-out'
          }
        ],
        onError: [
          {
            target: 'provider-not-found',
            cond: (ctx: any, event: any) => event.data && event.data.message && event.data.message.indexOf('not own any providers') !== -1
          },
          {
            target: 'retry-provider-log-in',
            actions: assign({
              error: (ctx: any, event: any) => event.data
            })
          }
        ]
      }
    },
    'provider-not-found': {
      on: {
        ONBOARDED: {
          target: 'check-auth-state'
        }
      }
    },
    'provider-onboarding': {
      on: {
        ONBOARDED: {
          target: 'check-auth-state'
        }
      }
    },
    'retry-provider-log-in': {
      after: {
        0: {
          target: 'error',
          cond: ({ retries, maxRetries }: any) => retries === maxRetries,
          actions: assign((context: any) => ({ error: context.error.message }))
        },
        2000: {
          target: 'user-logged-in',
          cond: ({ retries, maxRetries }: any) => retries < maxRetries,
          actions: assign({ retries: (context: any) => context.retries + 1 })
        }
      }
    },
    'provider-logged-in': {
      on: {
        UPDATE: {},
        ONBOARDED: {
          target: 'check-auth-state'
        },
        LOG_OUT: {
          target: 'user-logged-out'
        }
      }
    },
    error: {}
  }
};

const App = () => {
  const [state, send] = useMachine(createMachine<any, any>(AppStartMachine));
  return (
    <Router>
      <ChakraProvider
        theme={extendTheme(theme, {
          textStyles: {
            body: {
              fontFamily:
                "'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji','Segoe UI Emoji', 'Segoe UI Symbol'"
            }
          },
          styles: {
            global: {
              body: {
                backgroundColor: '#edf2f7'
              }
            }
          }
        })}
      >
        <div style={{ height: '100%', width: '100%' }}>
          <Route exact={true} path="/p/logout">
            <LogOut
              onLogout={() => {
                send('LOG_OUT');
              }}
            />
          </Route>
          {state.value !== 'provider-logged-in' &&
            state.value !== 'user-logged-out' &&
            state.value !== 'provider-onboarding' &&
            state.value !== 'provider-not-found' && (
              <Content>
                <Flex flexDirection="column" justifyContent="space-around" alignItems="center" style={{ position: 'relative' }}>
                  {state.value === 'check-auth-state' && <LoaderWithMessage message="Checking auth state..." />}
                  {state.value === 'user-logged-in' && <LoaderWithMessage message="User signed in pulling provider information..." />}
                  {state.value === 'retry-provider-log-in' && (
                    <LoaderWithMessage message="User signed in pulling provider information..." />
                  )}
                  {state.value === 'error' && <ErrorMessage message={state.context.error} />}
                </Flex>
              </Content>
            )}
          {state.value === 'provider-not-found' && <Redirect to="/register/your-business-details" />}
          {state.value === 'provider-onboarding' && state.context.provider.onboardingState === 'SETUP_SERVICES' && (
            <Redirect to="/register/add-services" />
          )}
          {state.value === 'provider-onboarding' && state.context.provider.onboardingState === 'SETUP_TEAM_MEMBERS' && (
            <Redirect to="/register/add-team-members" />
          )}
          {state.value === 'provider-onboarding' && state.context.provider.onboardingState === 'PUBLISHED_OPENING_HOURS' && (
            <Redirect to="/register/published-opening-hours" />
          )}
          {state.value === 'provider-logged-in' && (
            <AuthContextProvider provider={state.context.provider} providerId={state.context.providerId}>
              <Route path="/p/:providerId">
                <LoggedIn />
              </Route>
              <Route exact={true} path="/">
                <Redirect to={`/p/${state.context.providerId}/bookings`} />
              </Route>
              <Route exact={true} path="/login">
                <Redirect to={`/p/${state.context.providerId}/bookings`} />
              </Route>
              <Route exact={true} path="/register">
                <Redirect to={`/p/${state.context.providerId}/bookings`} />
              </Route>
            </AuthContextProvider>
          )}
          {state.value === 'user-logged-out' &&
            window.location.pathname.indexOf('/register') === -1 &&
            window.location.pathname.indexOf('/join') === -1 && <Redirect to="/login" />}
          <Route path={['/register', '/join']}>
            <Registration
              providerId={state.context && state.context.provider ? state.context.provider.id : undefined}
              onOnboarded={() => {
                send('ONBOARDED');
              }}
            />
          </Route>
          <Route exact={true} path="/login">
            <Login
              onLogin={() => {
                send('LOG_IN');
              }}
            />
          </Route>
          <Route exact={true} path="/password-reset" component={PasswordReset} />
        </div>
      </ChakraProvider>
    </Router>
  );
};

const ErrorMessage = ({ message }: { message: string }) => {
  const { push } = useHistory();
  return (
    <React.Fragment>
      <Background />
      <Panel width="400px" mt={6}>
        <PanelHeader>We had a problem logging you in</PanelHeader>
        <PanelBody>
          <Alert status="error" rounded="md" mt={2}>
            <AlertIcon />
            <AlertDescription mr={2}>{message}</AlertDescription>
          </Alert>
        </PanelBody>
        <PanelFooter>
          <TertiaryButton
            mr={3}
            flexGrow={1}
            onClick={() => {
              push('/p/logout');
              window.location.pathname = '/login';
            }}
          >
            Logout and retry
          </TertiaryButton>
        </PanelFooter>
      </Panel>
    </React.Fragment>
  );
};

const LoaderWithMessage = ({ message }: { message: string }) => {
  return (
    <React.Fragment>
      <Background />
      <Flex height="100px" justifyContent="center" alignItems="center">
        <Loader />
      </Flex>
      <Text color="white" fontSize="lg">
        {message}
      </Text>
    </React.Fragment>
  );
};

export default App;
