import { Service, ServiceGroup } from '@booket-uk/api/dist/providers/types';
import _groupBy from 'lodash.groupby';
import { Reducer, useEffect, useReducer } from 'react';
import { useProviderId } from '../useProviderId';
import ProviderStore from '../../../Services/ProviderStore';

const reducer = (prev: any, action?: any) => {
  switch (action.type) {
    case 'LOADING':
      return {
        isLoading: true,
        services: [],
        groups: []
      };
    case 'LOADED':
      const servicesGroupedByGroupId = _groupBy(action.services, 'group.id');
      const groupsWithServices = action.groups;
      groupsWithServices.forEach((group: any) => {
        group.services = servicesGroupedByGroupId[group.id] || [];
        group.services.sort((a: any, b: any) => a.name.localeCompare(b.name));
      });
      groupsWithServices.sort((a: any, b: any) => a.name.localeCompare(b.name));
      return {
        isLoading: false,
        services: action.services,
        groups: groupsWithServices
      };
    default:
      throw new Error(`Unknown type, ${action.type}`);
  }
};

export const useServices = (): { isLoading: boolean; groups: ServiceGroup[]; services: Service[] } => {
  const { providerId } = useProviderId();
  const [{ isLoading, services, groups }, dispatch] = useReducer<Reducer<any, any>>(reducer, reducer(null, { type: 'LOADING' }));
  useEffect(() => {
    return ProviderStore.getInstance(providerId).listen(store => {
      dispatch({ type: 'LOADED', services: store.services, groups: store.groups });
    });
  }, [providerId, dispatch]);

  return { groups, services, isLoading };
};
