import { Select, SelectProps } from '@booket-uk/component-library';
import React from 'react';
import timeOptions from '../../../Components/timeOptions';
import options from '../../../Components/timeOptions';

interface TimeSelectProps extends Omit<SelectProps, 'onChange'> {
  notBefore?: string;
  onChange: (e: string) => void;
}

export const TimeSelect = (props: TimeSelectProps) => {
  let filtered = timeOptions;
  if (props.notBefore) {
    let reached = false;
    for (let i = 0; i < options.length; i++) {
      if (options[i] === props.notBefore) {
        filtered = [];
        reached = true;
      }
      if (reached) {
        filtered.push(options[i]);
      }
    }
  }
  const _onChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    props.onChange(e.currentTarget.value);
  };
  return (
    <Select {...props} onChange={_onChange}>
      {filtered.map(option => (
        <option key={option} value={option}>
          {option.substring(0, 2)}:{option.substring(2)}
        </option>
      ))}
    </Select>
  );
};
