import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { mapCodeToMessage } from '../../../Firebase/Auth';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  InputField,
  PrimaryButton,
  RadioField,
  SelectField,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@booket-uk/component-library';
import { Auth } from '../../../Firebase';
import Barber from '../../../undraw/undraw_cookie_love_ulvn.svg';
import Api from '../../../Services/Api';
import * as Sentry from '@sentry/browser';
import { referralStore } from '../YourDetails';
import firebase from 'firebase/app';

const businessTypes = [
  { value: 'barber', label: 'Barbershop' },
  { value: 'hair', label: 'Hair salon' },
  { value: 'nails', label: 'Nail bar' },
  { value: 'spa', label: 'Spa' },
  { value: 'massage', label: 'Massage' },
  { value: 'eyebrows', label: 'Eyebrows & lashes' },
  { value: 'tattooAndPiercing', label: 'Tattoo & Piercing' },
  { value: 'personalTrainer', label: 'Personal trainer' },
  { value: 'asthetics', label: 'Aesthetics' },
  { value: 'other', label: 'Other' },
  { value: 'waxing', label: 'Waxing studio' }
].sort((a, b) => a.label.localeCompare(b.label));

export const YourBusinessDetails = ({ onSuccess }: { onSuccess: ({ providerId }: { providerId: string }) => void }) => {
  const { register, handleSubmit, setError, formState } = useForm();

  const { errors } = formState;

  const [currentUser, setCurrentUser] = useState(Auth.currentUser);
  const [generalError, setGeneralError] = useState();
  const onSubmit = handleSubmit(async (data, e) => {
    e?.preventDefault();
    try {
      const referral = referralStore.getOptional();
      const resp = await Api.fetch('/Register', {
        method: 'POST',
        body: JSON.stringify({
          ...data,
          businessLocationType: data.businessAddressLine1 ? 'fixed' : 'mobile',
          user: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email
          },
          referredBy: referral ? referral.slug : null
        })
      });
      onSuccess({ providerId: resp.metadata.providerId });
    } catch (err) {
      if (err.detail) {
        setGeneralError(err.detail);
      } else {
        Sentry.captureException(err);
        setGeneralError(mapCodeToMessage(err.code));
      }
    }
  });

  useEffect(() => {
    return Auth.onAuthStateChanged((user: firebase.User | null) => {
      setCurrentUser(user);
    });
  }, []);

  return (
    <>
      <Flex className="hide-tablet" bg="cool-gray.200" alignItems="center" flexDirection="column" py={20} justifyContent="center">
        <Image src={Barber} boxSize="45%" />
        <Heading maxWidth={440} textAlign="center">
          Hi {currentUser && currentUser.displayName}
        </Heading>
        <Text fontSize="lg" mt={6} maxWidth={420} textAlign="center">
          Welcome to <strong>Booket</strong>! We're sure glad to have you here!
        </Text>
        <Text fontSize="lg" mt={6} maxWidth={420} textAlign="center">
          We want to get you up and running as quickly as possible so have put together three screens to gather all the important stuff! All
          of this can be changed when you get in - so if you're squeezed for time skip through them.
        </Text>
        <Text fontSize="sm" fontWeight="bold"></Text>
      </Flex>

      <form onSubmit={onSubmit}>
        <Box py={[4, 8, 16]} px={[8, 16]}>
          <Stack spacing={5}>
            <Heading pb={6}>Tell us about your business</Heading>

            {generalError && (
              <Alert status="error" rounded="md" variant="left-accent" mt={-4}>
                <AlertIcon alignSelf="flex-start" mt={1} />
                <Flex flexDirection="column">
                  <AlertTitle>Sorry, there seemes to be a problem!</AlertTitle>
                  <AlertDescription mr={2}>{generalError}</AlertDescription>
                </Flex>
              </Alert>
            )}

            <InputField
              inputProps={{ size: 'lg', placeholder: 'Enter name', autoComplete: 'off' }}
              type="text"
              label="Business name"
              name="businessName"
              register={register('businessName', {
                required: {
                  value: true,
                  message: 'Sorry! This is required!'
                }
              })}
              errors={errors}
            />

            <SelectField
              label="What best summarises your business"
              name="businessType"
              inputProps={{ size: 'lg' }}
              register={register('businessType', {
                required: { value: true, message: 'This helps us to direct the right support towards you' }
              })}
              errors={errors}
              variant="outline"
            >
              <option value="" selected={true} disabled={true} hidden={true}>
                Select an option
              </option>
              {businessTypes.map(type => (
                <option value={type.value}>{type.label}</option>
              ))}
              ;
            </SelectField>

            <InputField
              inputProps={{ size: 'lg', placeholder: 'Enter telephone number', autoComplete: 'off' }}
              type="tel"
              label="Telephone"
              name="businessTelephone"
              register={register('businessTelephone', {
                required: {
                  value: true,
                  message: "Sorry! We like to give you a call to see how you're getting on!"
                },
                pattern: {
                  value: new RegExp('[0-9\\+\\s#]+', 'i'),
                  message: "Sorry! We didn't recognise this as a phone number"
                }
              })}
              errors={errors}
            />

            <FormControl>
              <FormLabel htmlFor="email">How do you operate?</FormLabel>
              <RadioField
                // inputProps={{ size: "lg", placeholder: "Enter postcode" }}
                name="businessOperationMethod"
                label="How do you operate?"
                register={register("businessOperationMethod")}
                errors={errors}
                inputProps={{ size: 'lg' }}
                options={
                  [
                    { label: 'Sole trader', value: 'soleTrader', py: 1 },
                    { label: 'Limited company', value: 'ltdCompany', py: 1 },
                    { label: "I don't know", value: 'unknown', py: 1 }
                  ] as any
                }
              />
            </FormControl>

            <Tabs isFitted variant="soft-rounded">
              <TabList mb="1em">
                <Tab>Physical premises</Tab>
                <Tab>Mobile</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack spacing={5}>
                    <InputField
                      inputProps={{ size: 'lg', placeholder: 'Enter first line of address' }}
                      type="text"
                      name="businessAddressLine1"
                      label="First line of address"
                      register={register("businessAddressLine1")}
                      errors={errors}
                    />
                    <InputField
                      inputProps={{ size: 'lg', placeholder: 'Enter postcode' }}
                      type="text"
                      name="businessPostcode"
                      label="Postcode"
                      register={register("businessPostcode")}
                      errors={errors}
                    />
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <PrimaryButton size="lg" type="submit" isLoading={formState.isSubmitting}>
              Next
            </PrimaryButton>
          </Stack>
        </Box>
      </form>
    </>
  );
};
