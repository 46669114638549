import { Reducer, useEffect, useReducer } from 'react';
import ProviderStore, { IProviderStoreFields } from '../../../Services/ProviderStore';
import { useProviderId } from '../useProviderId';

const reducer = (prev: any, action?: any) => {
  switch (action.type) {
    case 'LOADING':
      return {
        isLoading: true,
        store: action.store
      };
    case 'LOADED':
      return {
        isLoading: false,
        store: action.data
      };
    default:
      throw new Error(`Unknown type, ${action.type}`);
  }
};

export const useStore = (): { isLoading: boolean; store: IProviderStoreFields } => {
  const { providerId } = useProviderId();
  const instance = ProviderStore.getInstance(providerId);

  const [state, dispatch] = useReducer<Reducer<any, { type: string; data: any }>>(
    reducer,
    reducer({}, { type: 'LOADING', store: instance })
  );
  useEffect(() => {
    return instance.listen(data => {
      dispatch({
        type: 'LOADED',
        data
      });
    });
  }, [providerId, instance]);
  return state;
};
