import * as React from 'react';
import { useEffect, useState } from 'react';
import { Provider, User } from '@booket-uk/api/dist/providers/types';
import Booket from '../../../Booket';
import { Auth } from '../../../Firebase';

interface AuthedState {
  providerId: string;
  provider?: Provider;
  user: User;
}

const AuthContext = React.createContext<AuthedState>({} as any);

export function AuthContextProvider({
  provider,
  providerId,
  children
}: React.PropsWithChildren<{ provider?: Provider; providerId: string }>) {
  const [state, setState] = useState<AuthedState>({ user: Auth.currentUser, providerId, provider });
  useEffect(() => {
    const unsub = Booket.providers.onSnapshot({ providerId }, snap => {
      if (snap.type === 'added') {
        return;
      }
      setState({ providerId: providerId, provider: snap.data(), user: Auth.currentUser });
    });
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [providerId]);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
}

//  Split out to improve performance
export function useAuthDetails() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthDetails must be used within a AuthContext');
  }
  return context;
}
