import { Auth } from '../Firebase';

export default class Api {
  static parseJSON(response) {
    return new Promise(resolve =>
      response.json().then(json =>
        resolve({
          status: response.status,
          ok: response.ok,
          json
        })
      )
    );
  }

  static async fetchWithoutProcessing(url, options) {
    const token = await Auth.currentUser.getIdToken();
    const extendHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    let headers = {};
    headers = Object.assign(headers, extendHeaders);
    if (options && options.headers) {
      headers = Object.assign(headers, extendHeaders, options.headers);
    }

    return fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + `` + url, {
      ...options,
      headers
    });
  }

  static async fetch(url, options) {
    return new Promise((resolve, reject) => {
      Api.fetchWithoutProcessing(url, options)
        .then(Api.parseJSON)
        .then(response => {
          if (response.ok) {
            return resolve(response.json);
          }
          // extract the error from the server's json
          return reject(response.json);
        })
        .catch(error =>
          reject({
            message: error.message
          })
        );
    });
  }
}
