import React from 'react';
import { mapCodeToMessage } from '../../../Firebase/Auth';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Heading,
  Image,
  PrimaryButton,
  SimpleGrid,
  Stack,
  TertiaryButton,
  Text
} from '@booket-uk/component-library';
import Barber from '../../../undraw/undraw_barber_3uel.svg';
import { ServiceEditor } from '../../LoggedIn/Settings/Services/v2';
import { BooketApi } from '../../../Booket';
import { useProviderId } from '../../hooks/useProviderId';
import { useState } from 'react';

export const RegisterServices = ({ onSuccess, onBack }: { onSuccess: () => void; onBack: () => void }) => {
  const { providerId } = useProviderId();
  const [error, setError] = useState();

  const onSubmit = async () => {
    try {
      // await Auth.signInWithEmailAndPassword(data.email, data.password);
      await BooketApi.providers.update(providerId, { onboardingState: 'ONBOARDED' });
      onSuccess();
    } catch (err) {
      const errorMessage = mapCodeToMessage(err.code);
      setError(errorMessage);
    }
  };

  return (
    <SimpleGrid columns={[1, 1, 1, 3]} style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
      <Flex className="hide-tablet" bg="cool-gray.200" alignItems="center" flexDirection="column" py={20} justifyContent="center">
        <Image src={Barber} boxSize="45%" />
        <Heading maxWidth={440} textAlign="center" fontSize="xl">
          Make an impression!
        </Heading>
        <Text fontSize="lg" mt={6} maxWidth={280} textAlign="center">
          72% of satisfied customers share their experience with more than 6 people!
        </Text>
        <Text fontSize="sm" fontWeight="bold"></Text>
      </Flex>
      <Box p={[4, 8, 16]} style={{ gridColumnStart: 2, gridColumnEnd: 4 }}>
        <Stack spacing={5}>
          <Heading pb={6}>Add some services</Heading>
          <Box>
            {error && (
              <Alert status="error" rounded="md" variant="left-accent">
                <AlertIcon alignSelf="flex-start" mb={2} />
                <Flex flexDirection="column">
                  <AlertTitle>Please retry</AlertTitle>
                  <AlertDescription mr={2} data-testid="error">
                    {error}
                  </AlertDescription>
                </Flex>
              </Alert>
            )}
            <ServiceEditor colorScheme="teal" features={['ONBOARDING']} />
          </Box>
          <Flex mt={8}>
            <TertiaryButton size="lg" mr={4} onClick={onBack}>
              Back
            </TertiaryButton>
            <PrimaryButton size="lg" width="full" onClick={onSubmit}>
              You're all done! Lets get started!
            </PrimaryButton>
          </Flex>
        </Stack>
      </Box>
    </SimpleGrid>
  );
};
