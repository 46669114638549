import { Auth, Firestore } from '../Firebase';
import Booket from '@booket-uk/api';
import firebase from 'firebase/app';

const booket = new Booket({
  firestore: Firestore,
  auth: Auth,
  api: process.env.REACT_APP_FIREBASE_FUNCTION_URL,
  FieldValue: firebase.firestore.FieldValue
});

export const BooketApi = booket;

export default booket;
