import { Redirect, Route, useHistory } from 'react-router-dom';
import { Background, Flex, SimpleGrid } from '@booket-uk/component-library';
import { BooketLogo } from '../../Components/BooketLogo';
import React, { useEffect, useState } from 'react';

import { YourDetails } from './YourDetails';
import { YourBusinessDetails } from './YourBusinessDetails';
import { YourPublishedOpeningHours } from './YourPublishedOpeningHours';
import { RegisterServices } from './YourServices';
import { YourTeamMembers } from './YourTeamMembers';
import { AuthContextProvider } from '../LoggedIn/ProviderContext';
import { BooketBrowserKeyValueStore } from '@booket-uk/storage';
import { log } from '../../log';

const onboardingStore = new BooketBrowserKeyValueStore<string>('onboarding-store', { logger: log });

export const Registration = ({ providerId: initialProviderId, onOnboarded }: { providerId?: string; onOnboarded: () => void }) => {
  const history = useHistory();
  const [providerId, setProviderId] = useState<string | undefined>(initialProviderId || onboardingStore.getOptional());

  useEffect(() => {
    if (initialProviderId) {
      onSetProviderId(initialProviderId);
    }
  }, [initialProviderId]);
  const onSetProviderId = (providerId: string) => {
    onboardingStore.put(providerId);
    setProviderId(providerId);
  };
  return (
    <div>
      <Background />
      <Flex pb={[6, 6, 6, 10]} pt={[6, 6, 6, 10]} justifyContent="center">
        <BooketLogo style={{ height: 28 }} />
      </Flex>
      <Flex justifyContent="center">
        <SimpleGrid
          columns={[1, 1, 1, 2]}
          m={[4, 4, 4, 0]}
          rounded="lg"
          boxShadow="md"
          overflowX="hidden"
          bg="white"
          width={['full', 'full', 'full', '80%']}
          maxWidth={1280}
          minHeight={400}
        >
          <Route path="/join" exact={true}>
            <YourDetails
              type="referral"
              onSuccess={() => {
                history.push('/register/your-business-details');
              }}
            />
          </Route>
          <Route path="/register" exact={true}>
            <Redirect to="/register/your-details" />
          </Route>
          <Route path="/register/your-details">
            <YourDetails
              onSuccess={() => {
                history.push('/register/your-business-details');
              }}
            />
          </Route>
          <Route path="/register/your-business-details">
            <YourBusinessDetails
              onSuccess={({ providerId }) => {
                history.push('/register/published-opening-hours');
                onSetProviderId(providerId);
              }}
            />
          </Route>
          {providerId && (
            <AuthContextProvider providerId={providerId}>
              <Route path="/register/published-opening-hours">
                <YourPublishedOpeningHours
                  onSuccess={() => {
                    history.push('/register/add-team-members');
                  }}
                />
              </Route>
              <Route path="/register/add-team-members">
                <YourTeamMembers
                  onBack={() => {
                    history.push('/register/published-opening-hours');
                  }}
                  onSuccess={() => {
                    history.push('/register/add-services');
                  }}
                />
              </Route>
              <Route path="/register/add-services">
                <RegisterServices
                  onBack={() => {
                    history.push('/register/add-team-members');
                  }}
                  onSuccess={() => {
                    history.push(`/p/${providerId}/bookings`);
                    onOnboarded();
                  }}
                />
              </Route>
            </AuthContextProvider>
          )}
        </SimpleGrid>
      </Flex>
    </div>
  );
};
