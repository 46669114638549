import { useTeamMembers } from '../../../../hooks/useTeamMembers';
import React, { useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  IconButton,
  InputField,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PrimaryButton,
  Skeleton,
  Stack,
  Tag,
  TertiaryButton,
  Text,
  useDisclosure,
  useToast
} from '@booket-uk/component-library';
import { useProviderId } from '../../../../hooks/useProviderId';
import { useForm } from 'react-hook-form';
import { BooketApi } from '../../../../../Booket';
import { useHistory } from 'react-router';
import { TeamMember } from '@booket-uk/api/dist/providers/types';
import { trackView } from '../../../../hooks/useTracking';
import { HiPencil, HiTrash } from 'react-icons/hi';

type Mode = 'QUICK_EDIT' | 'DELETABLE' | 'MANAGEABLE';

export const ManageTeamMembers = ({
  modes = ['QUICK_EDIT', 'MANAGEABLE'],
  colorScheme = 'indigo'
}: {
  modes?: Mode[];
  colorScheme?: string;
}) => {
  const { teamMembers, isLoading } = useTeamMembers();
  const toast = useToast();
  const { providerId } = useProviderId();
  const history = useHistory();
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const addTeamMemberModal = useDisclosure();
  const editTeamMemberModal = useDisclosure();

  const onEdit = (teamMember: any) => {
    setSelectedTeamMember(teamMember);
    editTeamMemberModal.onOpen();
  };

  const onDelete = (teamMember: TeamMember) => {
    BooketApi.providers.teamMembers
      .delete({ providerId, teamMemberId: teamMember.uid })
      .then(() => {
        toast({
          position: 'top',
          title: `Success`,
          description: `${teamMember.name} deleted`,
          isClosable: true,
          status: 'success'
        });
      })
      .catch(err => {
        toast({
          position: 'top',
          title: 'Error',
          description: err.message,
          status: 'error',
          isClosable: true
        });
      });
  };

  trackView('Settings | Team member');
  return (
    <Box maxWidth="800px">
      {isLoading && (
        <Box>
          <Skeleton height={68} mb={1} />
          <Skeleton height={68} mb={1} />
          <Skeleton height={68} mb={1} />
        </Box>
      )}
      {addTeamMemberModal.isOpen && <TeamMemberMutationModal {...addTeamMemberModal} />}
      {editTeamMemberModal.isOpen && selectedTeamMember && (
        <TeamMemberMutationModal {...editTeamMemberModal} mode="UPDATE" defaultValues={selectedTeamMember} />
      )}
      {!isLoading && (
        <Grid gap={1}>
          {teamMembers
            .filter(tm => !!tm)
            .map(teamMember => (
              <Flex
                key={teamMember.uid}
                p={4}
                px={6}
                borderColor="cool-gray.200"
                borderBottomWidth={1}
                borderStyle="solid"
                justifyContent="space-between"
              >
                <Flex>
                  <Box>
                    <Text fontWeight="bold">{teamMember.name || teamMember.displayName}</Text>
                    <Text color="cool-gray.600">{teamMember.additionalInfo}</Text>
                  </Box>
                </Flex>
                <Flex alignItems="center">
                  {teamMember.roles && teamMember.roles.some(role => role === 'ADMIN') && (
                    <Tag variant="solid" colorScheme="teal" mr={4}>
                      Admin
                    </Tag>
                  )}
                  {teamMember.status === -1 && (
                    <Tag variant="solid" colorScheme="red">
                      Inactive
                    </Tag>
                  )}
                  {modes.some(mode => mode === 'QUICK_EDIT') && (
                    <IconButton aria-label="Edit" icon={<HiPencil />} mr={2} onClick={() => onEdit(teamMember)} />
                  )}
                  {modes.some(mode => mode === 'MANAGEABLE') && (
                    <TertiaryButton
                      colorScheme="gray"
                      variant="solid"
                      mr={2}
                      onClick={() => history.push(`/p/${providerId}/manage/team/${teamMember.uid}`)}
                    >
                      Manage
                    </TertiaryButton>
                  )}
                  {modes.some(mode => mode === 'DELETABLE') && !teamMember.roles && (
                    <IconButton
                      colorScheme="red"
                      variant="ghost"
                      aria-label="Delete"
                      icon={<HiTrash />}
                      onClick={() => onDelete(teamMember)}
                    />
                  )}
                </Flex>
              </Flex>
            ))}
        </Grid>
      )}
      {!isLoading && (
        <Flex pt={4} justifyContent="space-between">
          <PrimaryButton width="full" size="lg" onClick={addTeamMemberModal.onOpen} colorScheme={colorScheme}>
            Add team member
          </PrimaryButton>
        </Flex>
      )}
    </Box>
  );
};

const DeleteTeamMemberModal = () => {};

const TeamMemberMutationModal = ({
  isOpen,
  onClose,
  mode = 'ADD',
  defaultValues = {
    name: '',
    additionalInfo: ''
  }
}: {
  isOpen: boolean;
  onClose: () => void;
  mode?: 'ADD' | 'UPDATE';
  defaultValues?: any;
}) => {
  const { providerId } = useProviderId();
  const { register, reset, handleSubmit, formState } = useForm({
    defaultValues
  });

  const { errors } = formState;

  const onSubmit = handleSubmit(async (data, e) => {
    e?.preventDefault();
    const teamMember: any = {
      ...defaultValues,
      ...data
    };
    if (mode === 'ADD') {
      await BooketApi.providers.teamMembers.add({ providerId, entry: teamMember });
    } else {
      const update = {
        name: data.name,
        additionalInfo: data.additionalInfo
      }
      await BooketApi.providers.teamMembers.update({ providerId, teamMemberId: teamMember.id, entry: update });
    }
    reset();
    onClose();
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" closeOnOverlayClick={!formState.isSubmitting}>
      <form onSubmit={onSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{mode === 'ADD' ? `Add team member` : `Update "${defaultValues.name}"`}</ModalHeader>
          <ModalBody>
            <Stack spacing={4}>
              <InputField
                isRequired={true}
                inputProps={{ size: 'lg', autoComplete: 'off', placeholder: 'Sarah' }}
                register={register('name', { required: { value: true, message: 'Required' } })}
                errors={errors}
                name="name"
                label="Name"
              />
              <InputField
                inputProps={{ size: 'lg', autoComplete: 'off', placeholder: 'Senior stylist' }}
                register={register('additionalInfo')}
                errors={errors}
                name="additionalInfo"
                label="Role"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <TertiaryButton
              isDisabled={formState.isSubmitting}
              colorScheme="red"
              mr={3}
              onClick={() => {
                reset();
                onClose();
              }}
              type="button"
            >
              Close
            </TertiaryButton>
            <PrimaryButton width="full" type="submit" isLoading={formState.isSubmitting}>
              {mode === 'ADD' ? `Add` : `Update`}
            </PrimaryButton>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </form>
    </Modal>
  );
};
