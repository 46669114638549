import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CustomInputField,
  Flex,
  FormLabel,
  Grid,
  IconButton,
  InputField,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  PrimaryButton,
  SimpleGrid,
  Skeleton,
  Stack,
  Tag,
  TertiaryButton,
  Text,
  useDisclosure,
  useToast,
  FormControl,
  CheckboxGroup,
  Checkbox,
  AlertTitle
} from '@booket-uk/component-library';
import { useServices } from 'Containers/hooks/useServices';
import { GBP } from 'Components/utils/currencies';
import { useFieldArray, useForm } from 'react-hook-form';
import { BooketApi } from '../../../../../Booket';
import { useProviderId } from '../../../../hooks/useProviderId';
import React, { useEffect, useState } from 'react';
import { useTeamMembers } from '../../../../hooks/useTeamMembers';
import randomColor from 'randomcolor';
import { Service, ServiceGroup } from '@booket-uk/api/dist/providers/types';
import NoServices from 'undraw/undraw_services_5tv9.svg';
import { useTracking } from '../../../../hooks/useTracking';
import { HiPencil, HiTrash } from 'react-icons/hi';

export const ServiceEditor = ({ features = ['WITH_EMPTY_SERVICES'], ...props }: any) => {
  const toast = useToast();
  const { trackView } = useTracking();
  useEffect(() => {
    trackView('Settings | Services');
  }, [trackView]);
  const { providerId } = useProviderId();
  const { groups, isLoading } = useServices();

  const onDeleteGroup = async (group: any) => {
    try {
      await BooketApi.services.deleteGroup({ providerId, groupId: group.id });
      toast({
        position: 'top',
        title: `Success`,
        description: `${group.name} deleted`,
        isClosable: true,
        status: 'success'
      });
    } catch (err) {
      toast({
        position: 'top',
        title: 'Error',
        description: err.message,
        status: 'error',
        isClosable: true
      });
    }
  };

  const onSaveGroup = (group: any) => {
    return BooketApi.services.createGroup({ providerId, group });
  };

  const onSaveService = (group: any, service: any) => {
    if (service.id) {
      return BooketApi.services.update({
        providerId,
        groupId: group.id,
        serviceId: service.id,
        newServiceData: service
      });
    } else {
      return BooketApi.services.create({ providerId, groupId: group.id, service });
    }
  };

  return (
    <>
      {isLoading && (
        <Box>
          <Skeleton height={78} mb={1} />
          <Skeleton height={78} mb={1} />
          <Skeleton height={78} mb={1} />
          <Skeleton height={78} mb={1} />
          <Skeleton height={78} mb={1} />
        </Box>
      )}
      <Box {...props}>
        <ServiceEditerInner
          groups={groups}
          onDeleteGroup={onDeleteGroup}
          onSaveGroup={onSaveGroup}
          onSaveService={onSaveService}
          features={features}
          colorScheme={props.colorScheme}
        />
      </Box>
    </>
  );
};

export const ServiceEditerInner = ({ groups, onDeleteGroup, onSaveGroup, onSaveService, features = [], colorScheme = 'indigo' }: any) => {
  const addServiceModal = useDisclosure();
  const addGroupModal = useDisclosure();
  const [activeGroupIndex, setCurrentGroup] = useState<number>(0);
  return (
    <>
      {addServiceModal.isOpen && <ServiceMutationModal {...addServiceModal} group={groups[activeGroupIndex]} onSave={onSaveService} />}
      {features.some((feat: string) => feat === 'ONBOARDING') && (
        <Alert status="info" rounded="md" variant="left-accent" mb={4} mt={0}>
          <AlertIcon alignSelf="flex-start" mt={1} />
          <Flex flexDirection="column">
            <AlertTitle>We cluster services into 'groups' to help keep things organised</AlertTitle>
            <AlertDescription mr={2}>
              We've pre-populated a few services common to your business type. Add a new group or click on a group to manage it's services
            </AlertDescription>
          </Flex>
        </Alert>
      )}
      <AddGroupModal onSaveGroup={onSaveGroup} {...addGroupModal} />
      <Flex pb={4} justifyContent="space-between">
        <PrimaryButton width="full" size="lg" onClick={addGroupModal.onOpen} colorScheme={colorScheme}>
          Add new group
        </PrimaryButton>
      </Flex>
      <Accordion
        allowToggle={true}
        defaultIndex={-1}
        onChange={active => {
          if (active !== undefined && active !== null) {
            setCurrentGroup(typeof active === 'number' ? active : active[0]);
          }
        }}
      >
        {groups.map((group: any) => (
          <AccordionItem key={group.id}>
            {({ isExpanded }) => (
              <>
                <AccordionButton _expanded={{ bg: 'cool-gray.50' }}>
                  <Flex flex="1" textAlign="left" flexDirection="row" alignItems="center" py={2}>
                    <Box bg={group.color ? group.color : 'cool-gray.200'} p={5} mr={6} rounded="full" boxShadow="inner" />
                    <Flex flexDirection="column">
                      <Text fontWeight="bold">{group.name}</Text>
                      <Text color="cool-gray.600">
                        {group.services
                          ? group.services.length > 1
                            ? `${group.services?.length} services`
                            : group.services.length === 1
                            ? '1 service'
                            : 'No services'
                          : 'No services'}
                      </Text>
                    </Flex>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} bg={isExpanded ? 'cool-gray.50' : ''}>
                  {group.type && group.type === 'BLOCKING' && (
                    <Alert status="info" rounded="md" mb={4}>
                      <AlertIcon alignSelf="flex-start" mt={1} />
                      <AlertDescription mr={2}>
                        Services in the "Blocking" group have no charge or customer and when booking in the calendar an optional "reason"
                        prompt will be displayed
                      </AlertDescription>
                    </Alert>
                  )}
                  {!group.services ||
                    (group.services.length === 0 && features.some((feat: string) => feat === 'WITH_EMPTY_SERVICES') && (
                      <Flex pb={4} alignItems="center" flexDirection="column">
                        <Image src={NoServices} rounded="full" boxSize="150px" />
                        <Text color="cool-gray.500" mt={3} maxWidth={200} textAlign="center">
                          Add a service to this group to get started.
                        </Text>
                      </Flex>
                    ))}
                  {group.services && (
                    <ServiceList services={group.services} group={group} confirmDelete={true} onSaveService={onSaveService} />
                  )}
                  <Flex mt={6}>
                    <TertiaryButton size="lg" fontSize="md" colorScheme="red" mr={4} onClick={() => onDeleteGroup(group)}>
                      Delete group
                    </TertiaryButton>
                    <PrimaryButton size="lg" width="full" onClick={addServiceModal.onOpen} colorScheme={colorScheme}>
                      Add service
                    </PrimaryButton>
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

const ServiceList = ({
  group,
  services,
  confirmDelete,
  onSaveService
}: {
  group: ServiceGroup;
  services: Service[];
  confirmDelete?: boolean;
  onSaveService: (group: any, service: any) => Promise<void>;
}) => {
  const { providerId } = useProviderId();
  const toast = useToast();
  const editServiceModal = useDisclosure();
  const deleteServiceModal = useDisclosure();
  const [service, setService] = useState<Service | undefined>();

  const onEdit = (service: Service) => {
    setService(service);
    editServiceModal.onOpen();
  };

  const onDelete = (service: Service) => {
    if (confirmDelete) {
      setService(service);
      deleteServiceModal.onOpen();
    } else {
      BooketApi.services
        .delete({ providerId, groupId: group.id, serviceId: service.id })
        .then(() => {
          toast({
            position: 'top',
            title: `Success`,
            description: `${service.name} deleted`,
            isClosable: true,
            status: 'success'
          });
        })
        .catch(err => {
          toast({
            position: 'top',
            title: 'Error',
            description: err.message,
            status: 'error',
            isClosable: true
          });
        });
    }
  };

  return (
    <>
      {service && editServiceModal.isOpen && (
        <ServiceMutationModal {...editServiceModal} group={group} defaultValues={service} mode="UPDATE" onSave={onSaveService} />
      )}
      {service && deleteServiceModal.isOpen && <ServiceDeleteModal {...deleteServiceModal} group={group} service={service} />}
      <Grid gap={1}>
        {services &&
          services.map((service: Service) => (
            <Flex
              key={service.id}
              p={4}
              px={6}
              borderColor="cool-gray.200"
              borderBottomWidth={1}
              borderStyle="solid"
              justifyContent="space-between"
            >
              <Box>
                <Text fontWeight="bold">{service.name}</Text>
                <Text color="cool-gray.600">
                  {service.durationOf.map(duration =>
                    duration.type === 'BUSY' ? `${duration.of} minutes busy, ` : `${duration.of} minutes available, `
                  )}
                </Text>
              </Box>
              <Flex alignItems="center">
                <Text fontSize="lg" mr={4} fontWeight="semibold">
                  {GBP(service.charge).format()}
                </Text>
                <IconButton aria-label="Edit" icon={<HiPencil />} onClick={() => onEdit(service)} mr={2} />
                <IconButton colorScheme="red" variant="ghost" aria-label="Delete" icon={<HiTrash />} onClick={() => onDelete(service)} />
              </Flex>
            </Flex>
          ))}
      </Grid>
    </>
  );
};

const ServiceDeleteModal = ({ group, service, isOpen, onClose }: any) => {
  const { providerId } = useProviderId();
  const toast = useToast();
  const { register, reset, handleSubmit, formState, watch } = useForm({
    defaultValues: {
      name: ''
    }
  });

  const { errors } = formState;

  const typedServiceName = watch('name');

  const onSubmit = handleSubmit(async data => {
    try {
      await BooketApi.services.delete({ providerId, groupId: group.id, serviceId: service.id });
      toast({
        position: 'top',
        title: `Success`,
        description: `${service.name} deleted`,
        isClosable: true,
        status: 'success'
      });
      onClose();
    } catch (err) {
      toast({
        position: 'top',
        title: 'Error',
        description: err.message,
        status: 'error',
        isClosable: true
      });
    }
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" closeOnOverlayClick={!formState.isSubmitting}>
      <form onSubmit={onSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete "{service.name}"</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="info" rounded="md" mb={4}>
              <AlertIcon alignSelf="flex-start" mt={1} />
              <AlertDescription mr={2}>
                No new bookings of this service will be possible. All current bookings will be warranted at their current price and
                durations. The service will be deleted on all team members.
              </AlertDescription>
            </Alert>
            <InputField
              inputProps={{ size: 'lg', autoComplete: 'off' }}
              register={register('name', { required: { value: true, message: 'Required' } })}
              errors={errors}
              name="name"
              label={`Type '${service.name}' and click confirm`}
              width="full"
            />
          </ModalBody>
          <ModalFooter>
            <TertiaryButton
              isDisabled={formState.isSubmitting}
              mr={3}
              onClick={() => {
                reset();
                onClose();
              }}
              type="button"
            >
              Close
            </TertiaryButton>
            <PrimaryButton
              width="full"
              type="submit"
              isLoading={formState.isSubmitting}
              isDisabled={(typedServiceName as string).toLowerCase() !== service.name.toLowerCase()}
              colorScheme="red"
            >
              Confirm deletion
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

const AddGroupModal = ({ isOpen, onClose, onSaveGroup }: any) => {
  const { register, reset, handleSubmit, formState } = useForm({
    defaultValues: {
      name: '',
      color: randomColor()
    }
  });

  const { errors } = formState;

  const onSubmit = handleSubmit(async data => {
    const group = {
      ...data,
      color: data.color || randomColor()
    };
    await onSaveGroup(group);
    onClose();
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" closeOnOverlayClick={!formState.isSubmitting}>
      <form onSubmit={onSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a new group</ModalHeader>
          <ModalBody>
            <InputField
              inputProps={{ size: 'lg', autoComplete: 'off' }}
              register={register('name', { required: { value: true, message: 'Required' } })}
              errors={errors}
              name="name"
              label="Group name"
              width="full"
            />
          </ModalBody>

          <ModalFooter>
            <TertiaryButton
              isDisabled={formState.isSubmitting}
              colorScheme="red"
              mr={3}
              onClick={() => {
                reset();
                onClose();
              }}
              type="button"
            >
              Close
            </TertiaryButton>
            <PrimaryButton width="full" type="submit" isLoading={formState.isSubmitting} isDisabled={!formState.isDirty}>
              Add
            </PrimaryButton>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </form>
    </Modal>
  );
};

const ServiceMutationModal = ({
  isOpen,
  onClose,
  group,
  mode = 'ADD',
  onSave,
  defaultValues = {
    name: '',
    charge: '',
    durationOf: [{ of: '30' }],
    offeredBy: undefined
  }
}: {
  isOpen: boolean;
  onClose: () => void;
  group: ServiceGroup;
  onSave: (group: any, service: any) => Promise<void>;
  mode?: 'ADD' | 'UPDATE';
  defaultValues?: any;
}) => {
  const { teamMembers, isLoading: isLoadingTeamMembers } = useTeamMembers();
  const [offeredBy, setOfferedBy] = useState<string[]>([]);
  const { register, control, reset, handleSubmit, formState, watch } = useForm({
    defaultValues
  });

  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'durationOf'
  });

  useEffect(() => {
    if (teamMembers) {
      if (defaultValues.offeredBy) {
        setOfferedBy(defaultValues.offeredBy);
      } else {
        setOfferedBy(teamMembers.map(tm => tm.uid));
      }
    }
  }, [isLoadingTeamMembers]);

  const onSubmit = handleSubmit(async data => {
    const service: any = {
      ...defaultValues,
      ...data,
      group: group,
      charge: data.charge || 0,
      isBookableOnline: data.isBookableOnline || 'YES',
      offeredBy,
      durationOf: data.durationOf.map((dur: any, index: number) => ({
        of: parseInt(dur.of),
        type: index % 2 ? 'AVAILABLE' : 'BUSY'
      }))
    };
    await onSave(group, service);
    reset();
    onClose();
  });

  if (!group || !teamMembers) {
    return null;
  }
  const isNotBlockingGroup = group && group.type !== 'BLOCKING';
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" closeOnOverlayClick={!formState.isSubmitting}>
      <form onSubmit={onSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{mode === 'ADD' ? `Add ${group.name}` : `Update "${watch('name')}"`}</ModalHeader>
          <ModalBody>
            <Flex>
              <Flex flexGrow={1} mr={isNotBlockingGroup ? 4 : 0}>
                <InputField
                  inputProps={{ size: 'lg', autoComplete: 'off' }}
                  register={register('name', { required: { value: true, message: 'Required' } })}
                  errors={errors}
                  name="name"
                  label="Service name"
                  width="full"
                />
              </Flex>
              {isNotBlockingGroup && (
                <Box flexBasis="140px">
                  <CustomInputField label="Charge" register={register('charge')} errors={errors}>
                    <InputGroup size="lg">
                      <InputLeftAddon children="£" />
                      <NumberInput min={0} precision={2} borderBottomLeftRadius={0} borderTopLeftRadius={0} value={watch('charge')}>
                        <NumberInputField
                          {...register('charge', { required: { value: true, message: 'Required' } })}
                          borderBottomLeftRadius={0}
                          borderTopLeftRadius={0}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </InputGroup>
                  </CustomInputField>
                </Box>
              )}
            </Flex>
            <Box mt={4}>
              <FormLabel>Durations</FormLabel>
              <SimpleGrid mt={2} columns={3} spacingY={3}>
                {fields.map((item, index, arr) => (
                  <React.Fragment key={item.id}>
                    <Box>{index % 2 ? <Tag colorScheme="green">Available</Tag> : <Tag colorScheme="red">Busy</Tag>}</Box>
                    <Flex width={160}>
                      <InputGroup>
                        <NumberInput min={0} precision={0} defaultValue={(item as any).of}>
                          <NumberInputField borderBottomRightRadius={0} borderTopRightRadius={0}
                                            {...register(`durationOf[${index}].of`)}
                                            name={`durationOf[${index}].of`} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <InputRightAddon>minutes</InputRightAddon>
                      </InputGroup>
                    </Flex>
                    <Flex justifyContent="flex-end">
                      {index === arr.length - 2 && (
                        <TertiaryButton colorScheme="red" onClick={() => remove([arr.length - 1, arr.length - 2])}>
                          Remove
                        </TertiaryButton>
                      )}
                      {index === arr.length - 1 && (
                        <TertiaryButton onClick={() => append([{ name: 'available' }, { name: 'busy' }])}>Split service</TertiaryButton>
                      )}
                    </Flex>
                  </React.Fragment>
                ))}
              </SimpleGrid>
              {teamMembers && teamMembers.length > 1 && (
                <React.Fragment>
                  <FormLabel mt={4}>Offered by</FormLabel>
                  {/*<Tabs isFitted variant="soft-rounded" mt={2} onChange={() => {}}>*/}
                  {/*  <TabList mb="1em">*/}
                  {/*    <Tab>All team members</Tab>*/}
                  {/*    <Tab>Select team members</Tab>*/}
                  {/*  </TabList>*/}
                  {/*  <TabPanels>*/}
                  {/*    <TabPanel>*/}
                  {/*    </TabPanel>*/}
                  {/*    <TabPanel px={6}>*/}
                  <Box px={4} pt={2}>
                    <FormControl>
                      <CheckboxGroup
                        value={offeredBy}
                        onChange={newValues => {
                          if (newValues) {
                            setOfferedBy(newValues as any);
                          }
                        }}
                      >
                        <Stack spacing="2">
                          {teamMembers &&
                            teamMembers.map(teamMember => (
                              <Checkbox key={teamMember.uid} value={teamMember.uid} name="offeredBy">
                                {teamMember.displayName || teamMember.name}
                              </Checkbox>
                            ))}
                        </Stack>
                      </CheckboxGroup>
                    </FormControl>
                  </Box>
                  {/*</TabPanel>*/}
                  {/*</TabPanels>*/}
                  {/*</Tabs>*/}
                </React.Fragment>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <TertiaryButton
              isDisabled={formState.isSubmitting}
              colorScheme="red"
              mr={3}
              onClick={() => {
                reset();
                onClose();
              }}
              type="button"
            >
              Close
            </TertiaryButton>
            <PrimaryButton width="full" type="submit" isLoading={formState.isSubmitting}>
              {mode === 'ADD' ? `Add` : `Update`}
            </PrimaryButton>
          </ModalFooter>
          <ModalCloseButton />
        </ModalContent>
      </form>
    </Modal>
  );
};
