import { TeamMember } from '@booket-uk/api/dist/providers/types';
import { useStore } from '../useStore';

export const useTeamMembers = () => {
  const { isLoading, store } = useStore();
  if (isLoading || !store) {
    return { isLoading: true, teamMembers: [] as TeamMember[] };
  }
  const teamMembers = store.teamMembers || [];
  teamMembers.forEach(tm => {
    if (tm.name) {
      tm.displayName = tm.name;
    } else {
      tm.name = tm.displayName;
    }
  });
  teamMembers.sort((a, b) => a.name.localeCompare(b.name));
  return { isLoading, teamMembers: store.teamMembers };
};
