import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const config = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

let firebaseApp = null;
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(config);
} else {
  firebaseApp = firebaseApp.apps[0];
}

export const Auth = firebaseApp.auth();
export const Firestore = firebaseApp.firestore();
export const Analytics = firebaseApp.analytics();

if (process.env.NODE_ENV !== 'test') {
  // Firestore.clearPersistence();
  // Firestore.enablePersistence()
  //   .then(() => {
  //     log.debug('[Firestore] Offline persistence enabled');
  //   })
  //   .catch(err => {
  //     log.error('[Firestore] Offline persistence disabled. Reverting to server side processing only.', err);
  //   });
}
export const firebaseRaw = firebase;
