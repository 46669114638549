import { useCallback, useMemo } from 'react';
import GoogleAnalytics from 'react-ga';
import { Analytics } from '../../../Firebase';

GoogleAnalytics.initialize('UA-133682160-1');

export const trackView = (title: string) => {
  (window as any).fbq('track', 'PageView');
  GoogleAnalytics.pageview(window.location.pathname + window.location.search, [], title);
};

export const useTracking = () => {
  const fbq = useMemo(() => {
    return (window as any).fbq;
  }, [(window as any).fbq]);

  const trackView = useCallback(
    (title: string) => {
      fbq('track', 'PageView');
      GoogleAnalytics.pageview(window.location.pathname + window.location.search, [], title);
    },
    [fbq]
  );

  const trackPurchase = useCallback(
    ({ total, contents }: { total: number; contents: Array<{ id: string; quantity: number }> }) => {
      fbq('track', 'Purchase', {
        currency: 'GBP',
        value: total,
        contents,
        content_type: 'product'
      });
    },
    [fbq]
  );

  const trackEvent = useCallback((eventName: string, metadata?: any) => {
    Analytics.logEvent(eventName, metadata);
  }, []);

  return {
    trackView,
    trackPurchase,
    trackEvent
  };
};
