import { Auth } from '.';

export const getToken = async () => {
  return await Auth.currentUser.getIdToken();
};

export const mapCodeToMessage = code => {
  const error = errorCodeMap[code];
  if (!error) {
    console.log('Unknown code: ', code);
  }
  return error ? error : "We've got a problem here.. Try again";
};

const errorCodeMap = {
  'auth/invalid-email': 'Invalid Email Address',
  'auth/user-disabled': 'Your account has been disabled, Contact us',
  'auth/user-not-found': "We couldn't find an account with that email",
  'auth/weak-password': 'That passwords looking a bit weak! Try again',
  'auth/wrong-password': 'Looks like your passwords wrong',
  'auth/email-already-in-use': "We've already got your email in our records. Try resetting your password instead",
  'auth/requires-recent-login': 'Theres been a problem. Try logging out and back in before retrying this action',
  'auth/too-many-requests': 'To many recent failed login attempts. Please wait 5 minutes before trying again.',
  'auth/network-request-failed': 'There was an issue with your network. Check your wifi and internet connection and try again.',
  'auth/unauthorized-domain': "Looks like you've reached booket via an unverified domain. Please go to https://provider.booket.co"
};
