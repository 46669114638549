const quotes = [
  { by: 'Carol Burnett', quote: "When you have a dream, you've got to grab it and never let go" },
  { by: 'Audrey Hepburn', quote: 'Nothing is impossible. The word itself says "I\'m possible!"' },
  { by: 'Robin Williams', quote: 'No matter what people tell you, words and ideas can change the world' },
  { by: 'Rosa Parks​', quote: 'Each person must live their life as a model for others' },
  { by: 'Sheryl Sandberg', quote: 'Motivation comes from working on things we care about' },
  { by: 'Albert Einstein', quote: 'Creativity is intelligence having fun' },
  { by: 'Shahir Zag', quote: 'You will succeed because most people are lazy' },
  { by: 'Estee Lauder', quote: 'I never dreamed about success. I worked for it' },
  { by: 'Pablo Picasso', quote: 'Every child is an artist. The problem is how to remain an artist once we grow up' },
  { by: 'Theodore Roosevelt', quote: 'Believe you can and you’re halfway there' },
  { by: 'Oprah Winfrey', quote: 'Passion is energy. Feel the power that comes from focusing on what excites you.' },
  { by: 'Walt Disney', quote: 'The way to get started is to quit talking and begin doing' },
  { by: 'Walt Disney', quote: 'If you can dream it, you can do it' },
  { by: 'Vidal Sassoon', quote: 'The only place where success comes before work is in the dictionary' }
];

export const pickQuote = () => {
  return quotes[Math.floor(Math.random() * quotes.length)];
};
