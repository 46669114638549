import { Hours } from '@booket-uk/api/dist/providers/types';
import { DateTime } from 'luxon';
import { useStore } from '../useStore';

export type IS_OPEN_STATE = 'OPEN' | 'CLOSED' | 'UNKNOWN';
export const IS_OPEN_STATES = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  UNKNOWN: 'UNKNOWN'
};

export const fromHour = (date: DateTime, hours: string) => {
  return date.set({ hour: parseInt(hours.substring(0, 2), 10), minute: parseInt(hours.substring(2, 4), 10) });
};

export function putHoursOnToday(LSelectedDate: DateTime, hours: Hours[]) {
  return hours.map(hour => ({
    starts: fromHour(LSelectedDate, hour.start),
    ends: fromHour(LSelectedDate, hour.ends)
  }));
}

export interface ILuxonHours {
  starts: DateTime;
  ends: DateTime;
}

export const usePublishedHours = ({ selectedDate }: { selectedDate: string | DateTime }) => {
  const { isLoading, store } = useStore();

  if (isLoading) {
    return { isLoading, status: IS_OPEN_STATES.UNKNOWN as IS_OPEN_STATE, hours: [] };
  }

  const rv = {
    isLoading: false
  };

  const LSelectedDate = typeof selectedDate === 'string' ? DateTime.fromISO(selectedDate) : selectedDate;
  const selectedAsDate = LSelectedDate.toJSDate();
  const effectiveHours = store.provider.publishedHours.find(
    wh => selectedAsDate >= (wh.effectiveFrom as any).toDate() && selectedAsDate <= (wh.effectiveTo as any).toDate()
  );

  if (effectiveHours) {
    const hours: Hours[] = effectiveHours.hours[LSelectedDate.weekdayLong.toLowerCase()];
    if (hours && hours.length > 0) {
      const parsedHours = putHoursOnToday(LSelectedDate, hours);
      return {
        ...rv,
        status: IS_OPEN_STATES.OPEN as IS_OPEN_STATE,
        hours: parsedHours as ILuxonHours[],
        allHours: effectiveHours.hours
      };
    }
    return {
      isLoading: false,
      status: IS_OPEN_STATES.CLOSED as IS_OPEN_STATE,
      hours: [] as ILuxonHours[],
      allHours: effectiveHours.hours
    };
  }
  return { isLoading: false, status: IS_OPEN_STATES.CLOSED as IS_OPEN_STATE, hours: [] as ILuxonHours[] };
};
