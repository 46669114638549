import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { mapCodeToMessage } from '../../../Firebase/Auth';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CustomInputField,
  Flex,
  Heading,
  Image,
  Input,
  InputField,
  InputGroup,
  InputRightElement,
  Link,
  PrimaryButton,
  Stack,
  Text
} from '@booket-uk/component-library';
import Environment from '../../../undraw/undraw_environment_iaus.svg';
import { Auth } from '../../../Firebase';
import { useHistory, useLocation } from 'react-router';
import { BooketBrowserKeyValueStore } from '@booket-uk/storage';
import { log } from '../../../log';

export const referralStore = new BooketBrowserKeyValueStore<{ slug: string }>('referral-info', {
  logger: log
});
export const YourDetails = ({ onSuccess, type }: { onSuccess: () => void; type?: 'referral' }) => {
  document.title = 'Your details';
  const { register, handleSubmit, setError, formState } = useForm();

  const { errors } = formState;

  const { search } = useLocation();
  const history = useHistory();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  useEffect(() => {
    if (type === 'referral') {
      const queryParams = new URLSearchParams(search);
      if (!queryParams.has('referrer') || !queryParams.get('referrer')) {
        history.push('/register');
      }

      const referrerSlug = queryParams.get('referrer') as string;
      referralStore.put({ slug: referrerSlug });
    }
  }, []);

  const onSubmit = handleSubmit(async (data, e) => {
    try {
      const credential = await Auth.createUserWithEmailAndPassword(data.email, data.password);
      await credential.user?.updateProfile({ displayName: data.name });
      onSuccess();
    } catch (err) {
      if (err.code === 'auth/email-already-in-use') {
        setError('email', { type: 'custom', message: 'Sorry! That email has already been registered with us' });
        return;
      }

      if (err.code === 'auth/weak-password') {
        setError('password', { type: 'custom', message: 'Sorry! We require a stronger password than that' });
        return;
      }

      if (err.code === 'auth/invalid-email') {
        setError('email', { type: 'custom', message: 'Sorry! That email failed our validation' });
        return;
      }
      const errorMessage = mapCodeToMessage(err.code);
      setError('general', { type: 'custom', message: errorMessage });
    }
  });

  return (
    <>
      <Flex className="hide-tablet" bg="cool-gray.200" alignItems="center" flexDirection="column" py={20} justifyContent="center">
        <Image src={Environment} boxSize="55%" />
        <Heading mt={6} maxWidth={420} textAlign="center">
          {type === 'referral' ? 'A gift for you and your friend' : 'Beautiful things start small'}
        </Heading>
        <Text fontSize="lg" mt={6} maxWidth={420} textAlign="center">
          {type === 'referral'
            ? "We'll give you both £25 of SMS credits"
            : "We're committed to helping you grow your business into something special"}
        </Text>
        <Text fontSize="sm" fontWeight="bold"></Text>
      </Flex>
      <form onSubmit={onSubmit}>
        <Box py={[4, 8, 16]} px={[8, 16]}>
          <Stack spacing={5}>
            <Heading pb={6}>Tell us about yourself</Heading>

            {errors.general && (
              <Alert status="error" rounded="md" variant="left-accent" mt={-4}>
                <AlertIcon alignSelf="flex-start" mt={1} />
                <Flex flexDirection="column">
                  <AlertTitle>We were unable to verify your details</AlertTitle>
                  <AlertDescription mr={2}>{errors.general.message}</AlertDescription>
                </Flex>
              </Alert>
            )}

            <InputField
              inputProps={{ size: 'lg', placeholder: 'Enter name' }}
              type="text"
              name="name"
              label="Your name"
              register={register('name', {
                required: {
                  value: true,
                  message: 'Sorry! This is kinda important'
                }
              })}
              errors={errors}
            />

            <Alert status="info" rounded="md" variant="left-accent" mt={6}>
              <AlertIcon alignSelf="flex-start" mt={1} />
              <Flex flexDirection="column">
                <AlertDescription mr={2}>These will become your business login details</AlertDescription>
              </Flex>
            </Alert>
            <InputField
              inputProps={{ size: 'lg', placeholder: 'Enter email address' }}
              type="email"
              name="email"
              label="Email"
              register={register('email', {
                required: {
                  value: true,
                  message: "We can't log you in without this!"
                }
              })}
              errors={errors}
            />
            <Box>
              <CustomInputField label="Password" name="password" register={register("password")} errors={errors}>
                <InputGroup size="lg">
                  <Input
                    pr="4.5rem"
                    type={show ? 'text' : 'password'}
                    {...register('password', {
                      required: {
                        value: true,
                        message: "Sorry! We can't log you in without this!"
                      }
                    })}
                    placeholder="Enter password"
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </CustomInputField>
            </Box>
            <Text>
              By continuing you acknowledge and accept our{' '}
              <Link target="_blank" href="https://booket.co/legal/terms-and-conditions.html" color="blue.700">
                terms & conditions
              </Link>{' '}
              and{' '}
              <Link target="_blank" href="https://booket.co/legal/privacy-policy.html" color="blue.700">
                privacy policy
              </Link>
            </Text>
            <PrimaryButton size="lg" type="submit" isLoading={formState.isSubmitting}>
              Lets go!
            </PrimaryButton>
          </Stack>
        </Box>
      </form>
    </>
  );
};
