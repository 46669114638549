import React from 'react';
import {
  Background,
  Box,
  Button,
  CustomInputField,
  Flex,
  Heading,
  Image,
  Input,
  InputField,
  InputGroup,
  InputRightElement,
  Link,
  PrimaryButton,
  SimpleGrid,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@booket-uk/component-library';
import LoginImage from 'undraw/undraw_ideation_2a64.svg';
import { useForm } from 'react-hook-form';
import { Link as RouteLink, useHistory } from 'react-router-dom';
import { Auth } from '../../Firebase';
import { mapCodeToMessage } from '../../Firebase/Auth';
import { BooketLogo } from 'Components/BooketLogo';
import { pickQuote } from '../../utils/quote-picker';

const quote = pickQuote();

export const Login = ({ onLogin }: { onLogin: () => void }) => {
  const { register, handleSubmit, setError, formState, getValues } = useForm();

  const { errors } = formState;

  const history = useHistory();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const onSubmit = handleSubmit(async (data, e) => {
    try {
      await Auth.signInWithEmailAndPassword(data.email, data.password);
      onLogin();
    } catch (err) {
      if (err.code === 'auth/wrong-password') {
        setError('password', { type: 'custom', message: 'Incorrect password' });
        return;
      }
      if (err.code === 'auth/user-not-found') {
        setError('email', { type: 'custom', message: "We couldn't find a user with that email" });
        return;
      }
      const errorMessage = mapCodeToMessage(err.code);
      setError('general', { type: 'custom', message: errorMessage });
    }
  });

  return (
    <div>
      <Background />
      <Flex pb={[6, 6, 6, 10]} pt={[6, 6, 6, 10]} justifyContent="center">
        <BooketLogo style={{ height: 28 }} />
      </Flex>
      <Flex justifyContent="center">
        <SimpleGrid
          columns={[1, 1, 1, 2]}
          m={[4, 4, 4, 0]}
          rounded="lg"
          boxShadow="md"
          overflowX="hidden"
          bg="white"
          width={['full', 'full', 'full', '80%']}
          maxWidth={1280}
          minHeight={400}
        >
          <form onSubmit={onSubmit}>
            <Box py={[8, 16]} px={[8, 16]}>
              <Stack spacing={5}>
                <Heading pb={6}>Sign in</Heading>

                {errors.general && (
                  <Alert status="error" rounded="md" variant="left-accent" mt={-4}>
                    <AlertIcon alignSelf="flex-start" mt={1} />
                    <Flex flexDirection="column">
                      <AlertTitle>We were unable to verify your details</AlertTitle>
                      <AlertDescription mr={2}>{errors.general.message}</AlertDescription>
                    </Flex>
                  </Alert>
                )}

                <InputField
                  inputProps={{ size: 'lg', placeholder: 'Enter email' }}
                  type="email"
                  label="Email"
                  register={register('email', {
                    required: {
                      value: true,
                      message: "Sorry! We can't log you in without this!"
                    }
                  })}
                  errors={errors}
                />
                <Box>
                  <CustomInputField label="Password" name="password" register={register("password")} errors={errors}>
                    <InputGroup size="lg">
                      <Input
                        pr="4.5rem"
                        type={show ? 'text' : 'password'}
                        {...register('password', {
                          required: {
                            value: true,
                            message: "Sorry! We can't log you in without this!"
                          }
                        })}
                        placeholder="Enter password"
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? 'Hide' : 'Show'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </CustomInputField>
                  <Box mt={3}>
                    <Link as={RouteLink as any} {...{ to: { pathname: '/password-reset', state: { email: getValues().email } } }}>
                      Forgotten password?
                    </Link>
                  </Box>
                </Box>
                <PrimaryButton size="lg" type="submit" isLoading={formState.isSubmitting}>
                  Sign in
                </PrimaryButton>
                <Heading pt={6} fontSize="lg">
                  Don't have an account yet?
                </Heading>
                <PrimaryButton colorScheme="cool-gray" size="lg" onClick={() => history.push('/register')}>
                  Register
                </PrimaryButton>
              </Stack>
            </Box>
          </form>
          <Flex className="hide-tablet" bg="cool-gray.200" alignItems="center" flexDirection="column" py={20}>
            <Image src={LoginImage} boxSize="60%" />
            <Heading mt={6}>Lets make today great!</Heading>
            <Text fontSize="lg" mt={6} maxWidth={320} textAlign="center" fontStyle="italic">
              "{quote.quote}"
            </Text>
            <Text fontSize="sm" fontWeight="bold">
              {quote.by}
            </Text>
          </Flex>
        </SimpleGrid>
      </Flex>
    </div>
  );
};
