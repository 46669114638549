import { DateTime } from 'luxon';

DateTime.fromFirestore = (timestamp: any) => {
  if (timestamp.toMillis) {
    return DateTime.fromMillis(timestamp.toMillis());
  }
  if (timestamp.seconds) {
    return DateTime.fromMillis(timestamp.seconds * 1000);
  }
  return DateTime.fromJSDate(timestamp as any);
};
